import { Link } from "gatsby"
import * as React from "react"

import "./FooterBottom.sass"

class FooterBottom extends React.Component {

  render() {

    return (
      <div className='footer-bottom max-width'>
        <div className='footer-bottom__left'>
          © 2020 Youshido LLC. All rights reserved.
        </div>
        <div className='footer-bottom__right'>
          {/*<Link to='/' className='footer-bottom__link'>Privacy Policy</Link>*/}
          {/*<Link to='/' className='footer-bottom__link'>Cookie Policy</Link>*/}
          {/*<Link to='/' className='footer-bottom__link'>Terms and conditions</Link>*/}
        </div>
      </div>
    )
  }
}

export default FooterBottom
