import * as React from 'react';
import ArrowLeft from './icon-caret-left.svg';
import ArrowDown from './icon-caret-down.svg';
import ArrowDownRight from './icon-arrow-down-right.svg';
import DoubleQuotes from './icon-double-quotes.svg';
import CloudMessage from './icon-cloud-comment.svg';
import Idea from './icon-idea.svg';
import BorderedSuccess from './icon-bordered-success.svg';
import Facebook from './icon-facebook.svg';
import Twitter from './icon-twitter.svg';
import Linkedin from './icon-linkedin.svg';
import Medium from './icon-medium.svg';
import Behance from './icon-behance.svg';
import LogoWhite from './icon-logo-white.svg';
import Aim from './aim-with-arrow.svg';
import SolutionPuzzle from './solution-puzzle.svg';
import Puzzle from './puzzle.svg';
import CheckRound from './check-round.svg';
import AppStore from './app-store.svg';
import GooglePlay from './google-play.svg';
import VisitSite from './icon-visit-site.svg';

import MobileApp from './icon-mobile-app.svg';
import SolutionArchitecture from './icon-solution-architecture.svg';
import WebsitePlatform from './icon-website-platform.svg';

import PlanetaKino from './projects/planeta-kino.svg';
import Onefile from './projects/onefile.svg';
import Cloudkeyz from './projects/cloudkeyz.svg';
import Sphaeris from './projects/sphaeris.svg';
import Motivetica from './projects/motivetica.svg';
import Uvca from './projects/uvca.svg';
import Gtd from './projects/gtd.svg';
import Pushsend from './projects/pushsend.svg';
import OkeanElzy from './projects/okean-elzy.svg';
import Helper from './projects/helper.svg';
import Vichy from './projects/vichy.svg';
import Milka from './projects/milka.svg';
import Viasat from './projects/viasat.svg';
import IqBc from './projects/iq-bc.svg';
import Publbox from './projects/publbox.svg';
import MyShowdown from './projects/my-showdown.svg';
import Aprise from './projects/aprise.svg';
import PlanetaSmall from './projects/planeta-small.svg';
import CloudkeyzSmall from './projects/cloudkeyz-small.svg';

import ClientAxe from './Clients/axe.svg';
import ClientCloudkeyz from './Clients/cloudkeyz.svg';
import ClientDurex from './Clients/durex.svg';
import ClientHenkel from './Clients/henkel.svg';
import ClientKinder from './Clients/kinder.svg';
import ClientMasterCard from './Clients/mastercard.svg';
import ClientMilka from './Clients/milka.svg';
import ClientPepsi from './Clients/pepsi.svg';
import ClientPhilips from './Clients/philips.svg';
import ClientRaffaello from './Clients/raffaello.svg';
import ClientSaatchi from './Clients/saatchi-and-saatchi.svg';
import ClientViasat from './Clients/viasat.svg';
import ClientVichy from './Clients/vichy.svg';

import TechSwift from './Technologies/swift.svg';
import TechNode from './Technologies/node-js.svg';
import TechMongo from './Technologies/mongodb.svg';
import TechWeb from './Technologies/web.svg';
import TechKotlin from './Technologies/kotlin.svg';
import TechRedis from './Technologies/redis.svg';
import TechGraphql from './Technologies/graphql.svg';
import TechAngular from './Technologies/angular.svg';
import TechApollo from './Technologies/apollo.svg';
import TechReact from './Technologies/react-logo.svg';
import TechPostgre from './Technologies/postgresql.svg';
import TechWebsockets from './Technologies/web-sockets.svg';

import AboutMobileApps from './icon-about-mobile-apps.svg';
import AboutSolution from './icon-about-solution.svg';
import AboutWebsites from './icon-about-websites.svg';

import cn from 'classnames';

import './Icon.sass';

const headingIcons = {
  'heading-menu'            : { style : { width : 472 } },
  'heading-about'           : { style : { width : 553 } },
  'heading-blog'            : { style : { width : 354 } },
  'heading-case'            : { style : { width : 420 } },
  'heading-clients'         : { style : { width : 592 } },
  'heading-contacts'        : { style : { width : 763 } },
  'heading-connect'         : { style : { width : 692 } },
  'heading-reviews'         : { style : { width : 680 } },
  'heading-works'           : { style : { width : 540 } },
  'heading-process'         : { style : { width : 636 } },
  'heading-services'        : { style : { width : 741 } },
  'heading-something-wrong' : { style : { width : 910 } },
  'heading-tech'            : { style : { width : 392 } },
  'heading-vision'          : { style : { width : 514 } },
  'heading-num1'            : { style : { width : 167 } },
  'heading-num2'            : { style : { width : 200 } },
  'heading-num3'            : { style : { width : 200 } },
  'heading-num4'            : { style : { width : 214 } },
  'heading-num5'            : { style : { width : 202 } },
  'heading-num6'            : { style : { width : 208 } },
  'heading-num7'            : { style : { width : 198 } },
  'heading-inspiration'     : { style : { width : 944 } },
};

const iconMap = {
  'icon-logo-white'       : <LogoWhite/>,
  'icon-arrow-left'       : <ArrowLeft/>,
  'icon-arrow-down'       : <ArrowDown/>,
  'icon-arrow-down-right' : <ArrowDownRight/>,
  'icon-double-quotes'    : <DoubleQuotes/>,
  'icon-bordered-success' : <BorderedSuccess/>,
  'icon-cloud-message'    : <CloudMessage/>,
  'icon-idea'             : <Idea/>,
  'icon-aim'              : <Aim/>,
  'icon-solution-puzzle'  : <SolutionPuzzle/>,
  'icon-puzzle'           : <Puzzle/>,
  'icon-check-round'      : <CheckRound/>,
  'icon-app-store'        : <AppStore/>,
  'icon-google-play'      : <GooglePlay/>,
  'icon-visit-site'       : <VisitSite/>,

  'icon-mobile-app'            : <MobileApp/>,
  'icon-solution-architecture' : <SolutionArchitecture/>,
  'icon-website-platform'      : <WebsitePlatform/>,

  'icon-facebook' : <Facebook/>,
  'icon-twitter'  : <Twitter/>,
  'icon-linkedin' : <Linkedin/>,
  'icon-medium'   : <Medium/>,
  'icon-behance'  : <Behance/>,

  'planeta-kino'    : <PlanetaKino/>,
  'onefile'         : <Onefile/>,
  'cloudkeyz'       : <Cloudkeyz/>,
  'sphaeris'        : <Sphaeris/>,
  'motivetica'      : <Motivetica/>,
  'uvca'            : <Uvca/>,
  'gtd'             : <Gtd/>,
  'pushsend'        : <Pushsend/>,
  'okean-elzy'      : <OkeanElzy/>,
  'helper'          : <Helper/>,
  'vichy'           : <Vichy/>,
  'milka'           : <Milka/>,
  'viasat'          : <Viasat/>,
  'iq-bc'           : <IqBc/>,
  'publbox'         : <Publbox/>,
  'my-showdown'     : <MyShowdown/>,
  'aprise'          : <Aprise/>,
  'planeta-small'   : <PlanetaSmall/>,
  'cloudkeyz-small' : <CloudkeyzSmall/>,

  'client-axe'         : <ClientAxe/>,
  'client-cloudkeyz'   : <ClientCloudkeyz/>,
  'client-durex'       : <ClientDurex/>,
  'client-henkel'      : <ClientHenkel/>,
  'client-kinder'      : <ClientKinder/>,
  'client-master-card' : <ClientMasterCard/>,
  'client-milks'       : <ClientMilka/>,
  'client-pepsi'       : <ClientPepsi/>,
  'client-philips'     : <ClientPhilips/>,
  'client-raffaello'   : <ClientRaffaello/>,
  'client-saatchi'     : <ClientSaatchi/>,
  'client-viasat'      : <ClientViasat/>,
  'client-vichy'       : <ClientVichy/>,

  'swift'       : <TechSwift/>,
  'node-js'     : <TechNode/>,
  'mongodb'     : <TechMongo/>,
  'web'         : <TechWeb/>,
  'kotlin'      : <TechKotlin/>,
  'redis'       : <TechRedis/>,
  'graphql'     : <TechGraphql/>,
  'angular'     : <TechAngular/>,
  'apollo'      : <TechApollo/>,
  'react'       : <TechReact/>,
  'postgresql'  : <TechPostgre/>,
  'web-sockets' : <TechWebsockets/>,

  'about-mobile-apps' : <AboutMobileApps/>,
  'about-solution'    : <AboutSolution/>,
  'about-websites'    : <AboutWebsites/>,
};

const Icon = (props) => {
  let component = iconMap[props.name];

  if (headingIcons[props.name]) {
    const data = headingIcons[props.name];
    component = <img style={data.style} src={`/images/headings/${props.name.split('-').pop()}.png`}
                     alt={props.name}/>;
  }

  return (
    <div className={cn('icon', props.name, { 'icon-raw' : props.raw }, props.className)}
         style={props.style}>
      {component}
    </div>
  );
};

export default Icon;
