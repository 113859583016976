import * as React from "react"

import "../../styles/index.sass"
import "./PageWrapper.sass"

class PageWrapper extends React.Component<{}> {

  render() {
    return (
      <div className='page-wrapper'>
        {this.props.children}
      </div>
    )
  }
}

export default PageWrapper
